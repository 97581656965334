import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/home/home";
import Redirect from "./pages/redirect/index";
import Search from "./pages/search/search";
import Payed from "./components/pay/payed";
import Innovate from "./pages/innovate/innovate";
import Generate from "./pages/generate/generate";

export default function Router({ user, setLoginOpen, lang }) {
  return (
    <React.Fragment>
      <Routes>
        <Route
          path="/"
          element={<Home user={user} setLoginOpen={setLoginOpen} lang={lang} />}
        />
        <Route
          path="/search/:key"
          element={<Search user={user} example={false}  lang={lang} />}
        />
        <Route
          path="/example/:key"
          element={<Search user={user} example={true} lang={lang} />}
        />
        <Route path="/innovate" element={<Innovate lang={lang} />} />
        <Route path="/generate" element={<Generate lang={lang} />} />
        <Route path="/payed" element={<Payed lang={lang} />} />
        <Route path="/redirect/:key" element={<Redirect lang={lang} />} />
        <Route path="*" element={<Home user={user} lang={lang} />}></Route>
      </Routes>
    </React.Fragment>
  );
}
