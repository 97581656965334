import React, { useEffect, useState } from "react";
import {
  Modal,
  Radio,
  Space,
  Checkbox,
  message,
  Button,
  Row,
  Col,
  Input,
  Form,
} from "antd";
import styles from "./payModal.scss";
import { get } from "~/request";
import { ossServer } from "~/constant";
import { payWithAlipay } from "~/components/pay/alipay";
import { payWithPaypal } from "~/components/pay/paypal";
import Disclaimer from "../disclaimer/disclaimer";
import { payWithFree } from "./free";
import { createFromIconfontCN } from '@ant-design/icons';

const IconFont = createFromIconfontCN({
  scriptUrl: '//at.alicdn.com/t/c/font_4545419_wksgy0zcbf.js',
});

const secretKey = "mySecretKey"; // 用于加密的秘钥

const encryptCoupon = (coupon) => {
  return btoa(encodeURIComponent(coupon + secretKey)); // 简易加密方法
};

export default function PayModal({
  visible,
  setVisible,
  payItem,
  postData,
  onSuccess,
  lang,
}) {
  const [payMethod, setPayMethod] = useState(2);
  const [price, setPrice] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isFree, setIsFree] = useState(false);
  const [inputCoupon, setInputCoupon] = useState("");

  const onPay = () => {
    if (checked) {
      setVisible(false);
      if (payMethod === 1) {
        payWithAlipay({
          data: {
            ...postData,
            payItem,
          },
          onSuccess,
          keep: payItem === 3 || payItem === 4,
        });
      } else if (payMethod === 2) {
        payWithPaypal({
          data: {
            ...postData,
            payItem,
          },
          onSuccess,
        });
      } else if (payMethod === 3) {
        payWithFree({
          data: {
            ...postData,
            payItem,
          },
          onSuccess,
        });
      }
    } else {
      message.warning("Please check User License.");
    }
  };

  const getPrice = () => {
    get({
      url: `/price?payItem=${payItem}`,
      onSuccess: (data) => {
        setPrice(data);
      },
    });
  };

  useEffect(() => {
    getPrice();
  }, []);

  const permission = () => {
    if (checked) {
      setChecked(false);
    } else {
      setShowModal(true);
    }
  };
  const onDisclaimerConfirmed = () => {
    setChecked(true);
    setShowModal(false);
  };

  // 判断密钥是否正确
  const handleCouponValidation = () => {
    const encryptedCoupon = encryptCoupon(inputCoupon);
    const flag = atob(decodeURIComponent(encryptedCoupon)).includes("HKUSTGZ");
    setIsFree(flag);
    setPayMethod(flag ? 3 : 2);
  };

  return (
    <Modal
      title="Please select a payment method"
      open={visible}
      onOk={onPay}
      footer={
        <Button type="primary" disabled={!checked} onClick={onPay}>
          OK
        </Button>
      }
      onCancel={() => setVisible(false)}
    >
      {price && (
        <Radio.Group
          onChange={({ target: { value } }) => setPayMethod(parseInt(value))}
          value={payMethod}
        >
          <Space direction="vertical">
            {isFree ? (
              <Radio value={3}>
                <div className={styles.radioItem}>
                  Free for a limited time&nbsp;
                  <span style={{ textDecoration: "line-through" }}>
                    ${(price.usd / 100).toFixed(2)}
                  </span>
                </div>
              </Radio>
            ) : (
              <>
                {/* <Radio value={1}>
                  <div className={styles.radioItem}>
                    <img src={`${ossServer}/web/Alipay_logo.jpg`} alt="" />
                    Alipay(¥{(price.cny / 100).toFixed(2)})
                  </div>
                </Radio> */}
                <Radio value={2}>
                  <div className={styles.radioItem}>
                    <img src={`${ossServer}/web/favicon.ico`} alt="" />
                    Paypal(${(price.usd / 100).toFixed(2)})
                    &nbsp;
                    <IconFont type="icon-yinlian" />
                    <IconFont type="icon-visa1" />
                    <IconFont type="icon-american_express" />
                    <IconFont type="icon-MasterCard" />
                  </div>
                </Radio>
              </>
            )}
            <Space>
              <Input
                addonBefore="Enter COUPON"
                value={inputCoupon}
                onChange={(e) => setInputCoupon(e.target.value)}
              />
              <Button onClick={handleCouponValidation}>ENTER</Button>
            </Space>
            <br />
            <Checkbox checked={checked} onChange={permission}>
              User permission
            </Checkbox>
            <Disclaimer
              isModalOpen={showModal}
              onOk={onDisclaimerConfirmed}
              onCancel={() => {
                setShowModal(false);
              }}
              type="pay"
              lang={lang && lang}
            />
          </Space>
        </Radio.Group>
      )}
    </Modal>
  );
}
