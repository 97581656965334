import React from "react"
import {useParams} from "react-router-dom"

export default function Redirect() {

  const params = useParams()
  React.useEffect(() => {
    window.location.replace(`/search/${encodeURIComponent(atob(params.key))}`)
  }, [])

  return <div/>
}