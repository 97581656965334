import React, { useEffect, useState } from "react";
import { innovateKey } from "~/constant";
import PaperInnovation from "./paper";
import PatentInnovation from "./patent";

export default function Innovate({ lang }) {
  const [info, setInfo] = useState(null);

  useEffect(() => {
    const info = localStorage.getItem(innovateKey);
    if (!info) {
      window.history.back();
    } else {
      setInfo(JSON.parse(info));
    }
  }, []);

  return (
    <div style={{ paddingTop: 60 }}>
      {info?.type === "paper" && <PaperInnovation info={info} lang={lang} />}
      {info?.type === "patent" && <PatentInnovation info={info} lang={lang} />}
    </div>
  );
}
