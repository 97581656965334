import React from "react";
import {post} from "../../request";

export function payWithFree({data, onSuccess}) {
  post({
    url: "/freepay/create",
    data,
    onSuccess(res) {
      console.log(res);
      const {externalOrderId} = res
      onSuccess && onSuccess({externalOrderId, payMethod: 3})
    }
  })
}