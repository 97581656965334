import React, { useEffect, useState } from "react";
import styles from "./index.scss";
import { Button, Form, Input, Modal, Select, Row, Col, message } from "antd";
import { get } from "~/request";
const { Option } = Select;

export default function Login({ open, onCreate, onCancel }) {
  const [form] = Form.useForm();
  const phoneNo = Form.useWatch("phoneNo", form);
  const countryCode = Form.useWatch("countryCode", form);
  const email = Form.useWatch("email", form);
  const [loginType, setLoginType] = useState("email");
  const loginTypeTxt = {
    phoneNo: "Mobile number",
    email: "Email",
  };
  const countryCodeSelector = (
    <Form.Item name="countryCode" noStyle>
      <Select
        style={{
          width: 70,
        }}
      >
        <Option value="86">+86</Option>
        <Option value="87">+87</Option>
      </Select>
    </Form.Item>
  );
  const countDown = () => {
    var _count = document.getElementById("count"); //获取验证码按钮
    var time = 59;
    // 禁用按钮
    _count.disabled = true;
    // 开启定时器
    var timer = setInterval(function () {
      // 判断剩余秒数
      if (time == 0) {
        // 清除定时器和复原按钮
        clearInterval(timer);
        _count.disabled = false;
        _count.innerHTML = "Get verification code";
      } else {
        _count.innerHTML = `Retrieve verification code after ${time}s`;
        time--;
      }
    }, 1000);
  };
  const getCode = () => {
    if (loginType === "phoneNo") {
      get({
        url: "/login/sms",
        params: { phoneNo, countryCode },
        onSuccess: (data) => {
          if (data == "ok") {
            message.success("The verification code has been sent");
            countDown();
          }
        },
      });
    } else if (loginType === "email") {
      get({
        url: "/login/email",
        params: { email },
        onSuccess: (data) => {
          if (data == "ok") {
            message.success("The verification code has been sent");
            countDown();
          }
        },
      });
    }
  };
  const changeLoginType = () => {
    switch (loginType) {
      case "phoneNo":
        setLoginType("email");
        break;
      case "email":
        setLoginType("phoneNo");
        break;
      default:
        break;
    }
  };
  return (
    <Modal
      open={open}
      title={`${loginTypeTxt[loginType]} authentication login`}
      okText="Login / Registration"
      cancelButtonProps={{ style: { display: "none" } }}
      className={styles.loginModal}
      onCancel={onCancel}
      onOk={() => {
        form
          .validateFields()
          .then((values) => {
            form.resetFields();
            switch (loginType) {
              case "phoneNo":
                onCreate(values, "/login/sms");
                break;
              case "email":
                onCreate(values, "/login/email");
                break;
              default:
                break;
            }
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <Form
        form={form}
        layout="horizontal"
        name="form_in_modal"
        initialValues={{
          countryCode: "86",
        }}
      >
        <Form.Item
          name={loginType}
          rules={[
            {
              required: true,
              message: `Please enter your ${loginTypeTxt[loginType]}!`,
            },
          ]}
        >
          <Input
            placeholder={loginType}
            addonBefore={loginType === "phoneNo" && countryCodeSelector}
            style={{
              width: "100%",
            }}
          />
        </Form.Item>
        <Form.Item>
          <Row gutter={8}>
            <Col span={6}>
              <Form.Item
                name="verifyCode"
                noStyle
                rules={[
                  {
                    required: true,
                    message: `Please enter the verification code you obtained!`,
                  },
                ]}
              >
                <Input placeholder="code" />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Button type="primary" onClick={getCode} id="count">
                Get verification code
              </Button>
            </Col>
          </Row>
        </Form.Item>
        {/* <span
          style={{ cursor: "pointer", color: "#aeaaa8" }}
          onClick={changeLoginType}
        >
          切换为
          {loginType == "phoneNo"
            ? loginTypeTxt["email"]
            : loginTypeTxt["phoneNo"]}
          验证登录
        </span> */}
      </Form>
      <div className={styles.footerTxt}>
        By signing up, you agree to the{" "}
        <span className={styles.blue}>User Agreement</span> and{" "}
        <span className={styles.blue}>Privacy Policy</span>
        <br />
        New users will be automatically registered upon first login
      </div>
    </Modal>
  );
}
