import React from "react";
import {post} from "../../request";

export function payWithAlipay({data, onSuccess, keep}) {
  post({
    url: "/alipay/create",
    data,
    onSuccess(res) {
      console.log(res);
      const {body, id} = res
      if (body) {
        if (keep) {
          let win = window.open("about:blank")
          win.document.write(body)
          win.focus()

          let handler = setInterval(() => {
            if (win.closed) {
              clearInterval(handler)
              onSuccess && onSuccess({externalOrderId: id, payMethod: 1})
            }
          }, 1000)

        } else {
          const wrap = document.createElement("div")
          wrap.innerHTML = body
          document.body.appendChild(wrap)
          wrap.getElementsByTagName("form")[0].submit()
        }
      }
    }
  })
}